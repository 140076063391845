import React, { useReducer, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Fullscreen from "react-full-screen";
import Draggable from 'react-draggable';
import SimpleBar from "simplebar-react"

import './termynal.scss';


const WindowInitState = {
  hidden: false,
  isFull: false,
  position: {
    x: 0,
    y: 0,
  },
}
const WindowActions = (windowState, windowAction) => {
  switch (windowAction.type) {
    case "hide":
      return { ...windowState, hidden: !windowState.hidden }
    case "expand":
      return {
        ...windowState,
        isFull: windowAction.enabled || !windowState.isFull,
        hidden: false,
        position: { x: 0, y: 0 },
      }
    case "close":
      window.open("404", "_blank")
      return { ...windowState}
    case "drag":
      return {
        ...windowState,
        position: { x: windowAction.data.x, y: windowAction.data.y },
      }
    default:
      return windowState
  }
}

const Terminal = () => {
  const [windowState, dispatch] = useReducer(WindowActions, WindowInitState)
  return (
    <Fullscreen
      enabled={windowState.isFull}
      onChange={enabled => dispatch({ type: "expand", enabled })}
    >
      <Draggable
        handle=".buttons"
        position={windowState.position}
        onStop={(e, data) => dispatch({ type: "drag", data })}
      >
        <div className="col-12 terminal bg-term shadow">
          <div className="buttons">
            <button
              className="btn btn-term term-close"
              onClick={() => dispatch({ type: "close" })}
              title="Close"
            />
            <button
              className={`btn btn-term term-min ${
                windowState.isFull ? "disabled" : ""
              }`}
              onClick={() => dispatch({ type: "hide" })}
              disabled={windowState.isFull}
              title="Minimize"
            />
            <button
              className="btn btn-term term-max"
              onClick={() => dispatch({ type: "expand" })}
              title="Maximize"
            />
          </div>
          <Contents
            hidden={windowState.hidden}
            fullSreen={windowState.isFull}
          />
        </div>
      </Draggable>
    </Fullscreen>
  )
}
const Contents = (props) => {
  const data = useStaticQuery(graphql`
    query Experience {
      info: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/experience/" } }
        sort: { fields: frontmatter___end_date, order: DESC }
      ) {
        nodes {
          id
          frontmatter {
            company
            position
            team
            start_date(formatString: "MMMM YYYY")
            end_date(formatString: "MMMM YYYY")
            city
            state
          }
          rawMarkdownBody
        }
        totalCount
      }
    }
  `)
  return (
    <SimpleBar
      autoHide="false"
      id="termynal"
      className="inner-terminal"
      style={{
        display: props.hidden ? "none" : "block",
        maxHeight: props.fullSreen ? "none" : 500,
      }}
    >
      <div data-ty>
        <b className="text-blue">alejandromartinez.soy</b> on{" "}
        <b className="text-green">master</b> is 📦{" "}
        <b className="text-red">v3.0.1</b> via{" "}
        <b className="text-green">⬢ v12.13.1</b>
      </div>
      <div data-ty="input">tree ./experience</div>
      <div>
        <div data-ty="progress">.</div>

        {data.info.nodes.map((info, index) => (
          <div key={info.id}>
            <div
              data-ty
              data-ty-prompt={`├── [${info.frontmatter.city} / ${info.frontmatter.state}]`}
            >
              <OutboundLink
                className="text-red text-bold"
                target="_blank"
                rel="noopener noreferrer"
                href={info.frontmatter.link}
              >
                {info.frontmatter.company}
              </OutboundLink>
            </div>
            <div
              data-ty
              data-ty-prompt={`│ └── (${info.frontmatter.start_date} - ${info.frontmatter.end_date})`}
            >
              <b>{info.frontmatter.position}</b>
              <div className="job-desc">
                {info.rawMarkdownBody.split("\n").map(tab => (
                  <div key={tab} className="tab">
                    {tab}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div data-ty data-ty-prompt="└──">
          <span role="img" aria-label="Done">
            ✨
          </span>
          <span className="text-blue">Done:</span> {data.info.totalCount} work
          positions.
        </div>
      </div>
    </SimpleBar>
  )}

Terminal.defaultProps = { hidden: false };

export default Terminal;