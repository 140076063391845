import React, {useContext} from "react";
import {Grid, Typography, Fab} from "@material-ui/core";
import {FiDownloadCloud} from "react-icons/fi";
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Terminal from '../Terminal';
import ThemeContext from '../../context/ThemeContext'
import './resume.scss';

const Resume = () => {
  const {darkMode} = useContext(ThemeContext)
  return (
    <Grid container justify="space-around" style={{ padding: "2vw 0" }}>
      <Grid item sm={11} lg={3} className="resume-info">
        <Typography variant="h4" className="resume-info-title">
          My Work Experience
        </Typography>
        <Typography className="resume-info-subtitle">
          This is equivalent to my resume, but you can download a PDF version below.
        </Typography>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          className={`button-gradient${darkMode ? "-dark" : ""}`}
          aria-label="download"
        >
          <FiDownloadCloud size="1.5rem" className="button-svg"/>
          Download as PDF
        </Fab>
      </Grid>
      <Grid item xs={11} lg={7}>
        <Terminal></Terminal>
      </Grid>
    </Grid>
  )
}
export default Resume;