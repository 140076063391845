import React, { useContext } from "react"
// import { OutboundLink } from 'gatsby-plugin-google-analytics'
import GitHubCalendar from 'react-github-contributions-calendar'
import Draggable from 'react-draggable'
import { Grid, Typography, Card, CardContent } from "@material-ui/core"
import { red, green } from '@material-ui/core/colors'

import { useLocalJsonForm } from "gatsby-tinacms-json"
import { useStaticQuery, graphql } from "gatsby"
import { FiGithub } from "react-icons/fi"

import ThemeContext from "../../context/ThemeContext"

import HandWaveSVG from "../../images/handwave.svg"
import TopHatSVG from "../../images/tophat.svg"

import './aboutme.scss'

const AboutMe = () => {
    const data = useStaticQuery(graphql`
        query HeaderImageQuery {
            profileImage: file(relativePath: { eq: "profile.jpeg" }) {
                childImageSharp {
                    fluid(quality:100,maxWidth:600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            author: dataJson(pk: { eq: "author" }) {
                aboutme
                github
                fileRelativePath
                rawJson
            }
        }
    `)
    const [{ aboutme, github }] = useLocalJsonForm(data.author, {
        label: "About Me",
        fields: [
            { name: 'rawJson.aboutme', label: "Author Bio", component: "textarea" },
            { name: 'rawJson.github', label: "Github User Name", component: "text"},
        ]
    })
    const {darkMode} = useContext(ThemeContext)
    const defaultTheme = {
        background: `rgba(0,0,0,0)`,
        text: darkMode ? `#fff` : `#000`,
        grade4: darkMode ? red[900] : green[900],
        grade3: darkMode ? red[800] : green[800],
        grade2: darkMode ? red[700] : green[700],
        grade1: darkMode ? red[600] : green[600],
        grade0: darkMode ? `rgba(255,255,255,0.1)` : `rgba(0,0,0,0.1)`,
    }
    return (
      <section className={`section section-wrapper bg-gradient-red`}>
        <div
          className="bg-gradient-green-dark dark"
          style={{ opacity: darkMode ? 1 : 0 }}
        />
        <Grid container justify="center" className="inner-wrapper" spacing={5}>
          {/* <Grid item md={4} lg={3} className="profile-image-grid">
                    <Paper
                        className="profile-image-container transform-perspective-left"
                        elevation={5}
                    >
                        <Img
                            fluid={data.profileImage.childImageSharp.fluid}
                            className="profileImage"
                            objectFit="cover"
                            objectPosition="top center"
                            draggable={false}
                            fadeIn
                        />
                    </Paper>
                </Grid> */}
          <Grid item md={8}>
            <Typography variant="h3" color="textPrimary" id="name">
              Who am I?
              <div className="aboutme-emoji-fun">
                <Draggable>
                  <div>
                    <TopHatSVG
                      role="img"
                      className={`emoji-slant${darkMode ? "-dark" : ""}`}
                      id="tophat"
                      aria-label="tophat"
                      style={{
                        position: `absolute`,
                        left: `-10px`,
                        top: `-5px`,
                        zIndex: 1,
                        height: 100,
                        width: 100,
                      }}
                    />
                  </div>
                </Draggable>
                <HandWaveSVG
                  style={{
                    zIndex: -1,
                    fontSize: `3rem`,
                    position: `relative`,
                    left: `5px`,
                    top: `15px`,
                    width: 100,
                    height: 100,
                  }}
                  className="emoji-slant"
                  id="wave"
                />
              </div>
            </Typography>
            <Typography color="textPrimary" className="aboutme-body">
              {aboutme}
            </Typography>
            <Grid container>
              <Grid item sm={9} md={12} lg={6}>
                <Card raised elevation={5}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      <FiGithub></FiGithub>
                    </Typography>

                    <GitHubCalendar
                      username={github}
                      theme={defaultTheme}
                      tooltips
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    )
}

export default AboutMe
