import React from "react"
import { Link, graphql } from "gatsby"
import { Grid, Typography, Fab } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import AboutMe from "../components/AboutMe"
import WorkHistory from "../components/WorkHistory"
import CardContainer from "../components/Card"
import Resume from "../components/Resume"
import { FiDownloadCloud } from "react-icons/fi"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = "data.site.siteMetadata.title"
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <Hero />
        <AboutMe></AboutMe>
        <WorkHistory></WorkHistory>
        <CardContainer post={posts}></CardContainer>
        <Resume></Resume>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
         query {
           allMarkdownRemark(
             sort: { order: ASC, fields: [frontmatter___order] }
             filter: { fields: { slug: { regex: "/projects/" } } }
           ) {
             edges {
               node {
                 excerpt
                 fields {
                   slug
                 }
                 frontmatter {
                   title
                   badges
                   date
                   description
                   altTitle
                   icon
                   link
                   color
                   order
                   company
                   position
                   team
                   start_date
                   end_date
                 }
               }
             }
           }
         }
       `
