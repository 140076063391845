import React, { useContext } from "react"
import { Grid, Container, Typography, IconButton } from "@material-ui/core"
import ThemeContext from "../../context/ThemeContext"
import "./workhistory.scss"
import * as Companies from "../../images/companies"
import { FiBriefcase } from "react-icons/fi"

const WorkHistory = () => {
    const { darkMode } = useContext(ThemeContext)
    const logoClass = `company-logo ${darkMode ? `dark` : ``}`
    return (
        <>
            <Grid container xs={10} alignItems="center" style={{ margin: `15px auto` }}>
                {/* <div className={`icon-circle bg-gradient-${darkMode ? `green` : `red`}`}><FiBriefcase /></div> */}
                <Typography variant="h4" color="textPrimary" style={{ textTransform: `uppercase` }} >Who I’ve Worked With</Typography>
            </Grid>
            <Grid
                container
                sm={8}
                justify="space-evenly"
                alignItems="center"
                style={{ margin: `auto` }}
            >
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.Exponent className={logoClass}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.Slate className={logoClass}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.Csun className={logoClass}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.Tesla className={logoClass} justify="center" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.MetaLab className={logoClass}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="company-grid">
                    <Companies.Macys className={logoClass}/>
                </Grid>
            </Grid>
        </>
    )
}
export default WorkHistory
