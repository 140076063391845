import React from "react";
import {Typography} from "@material-ui/core"
import SimpleBar from "simplebar-react"

import './card.scss';


const ProjectCard = ({ frontmatter , excerpt, wide}) => {
  return (
    <div className={`project-card ${wide ? "project-card-wide" : ""}`}>
      <div
        className="project-card-wrapper"
        style={{
          backgroundImage:
            frontmatter.order == 0
              ? "url(https://media.licdn.com/dms/image/C5612AQFiMOpqaLMumg/article-cover_image-shrink_600_2000/0?e=1580342400&v=beta&t=3WCmYYXe6k7de141uDrlGeIUxi8FnjBuAg3_npKFGHk)"
              : `url(https://picsum.photos/1080?random=${frontmatter.order})`,
        }}
      >
        <div className="project-card-inner">
          <Typography
            variant={wide ? "h4" : "h6"}
            className={wide ? "card-title-wide" : "card-title"}
          >
            Connecting a Race Vehicle to the Web
          </Typography>
          <Typography>
            a project for <b>CSUN Matador Motorsports</b>
          </Typography>
          <Typography className="card-detail">
            6 months ago • 2 min read{" "}
          </Typography>
        </div>
      </div>
    </div>
  )
}
const CardContainer = ({post}) => {
  return (
    <section className="projects">
      <SimpleBar>
        {post.map(({ node }, index) => (
          <ProjectCard wide={!index} {...node}></ProjectCard>
        ))}
      </SimpleBar>
    </section>
  )
}
export default CardContainer;