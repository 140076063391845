import React, { useContext } from "react"
import Particles from 'react-particles-js'
import { Grid,Typography } from "@material-ui/core"

import Img from "gatsby-image"
import "./hero.scss"
import particlesJSON from './particles.json'

import { useStaticQuery, graphql } from "gatsby"
import ThemeContext from "../../context/ThemeContext"

const Text = ({ children }) => (
    <Typography variant="h1" className="hero-text">
        {children}
    </Typography>
)
const SubText = ({ children }) => (
    <Typography variant="h2" className="hero-text">
        {children}
    </Typography>
)

const Hero = () => {
    const {darkMode} = useContext(ThemeContext)
    const data = useStaticQuery(graphql`
        query StickerImg {
            sticker: file(relativePath: { eq: "sticker.png" }) {
                childImageSharp {
                    fluid(fit: CONTAIN) {
                        ...GatsbyImageSharpFluid,
                    }
                }
            }
        }
    `)
    return (
        <div className="hero-section">
            <Grid
                container
                justify="center"
                alignItems="center"
                className="hero-text-center"
            >
                <Grid item spacing={10} className="hero-inner">
                    <SubText>Hola. Soy</SubText>
                    <Text>Alejandro</Text>
                    <Text>
                        And I
                        <div
                            className="highlight bg-gradient-green"
                            id="dark"
                            style={{ opacity: darkMode ? 1 : 0 }}
                        >
                            &nbsp;engineer{` `}
                        </div>
                        <div className="highlight bg-gradient-red">
                            &nbsp;engineer{` `}
                        </div>
                    </Text>
                    <Text>
                        software
                        <Img
                            fixed={data.sticker.childImageSharp.fluid}
                            className="sticker"
                            fadeIn={false}
                            draggable={false}
                        />
                    </Text>
                </Grid>
            </Grid>
            <Particles className="hero" params={particlesJSON} />
        </div>
    )
}

export default Hero
